<template>
  <div>
    <div class="nav-bar pc" id="top-nav-bar">
      <div class="nav-bar-fixed">
        <div class="nav-bar-container">
          <div class="nav-bar-logo" @click="goHome">
            <img :src="logo" alt="" />
          </div>
          <div class="nav-bar-right">
            <el-menu
              :default-active="$route.path"
              :router="true"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              text-color="#000000"
              active-text-color="#409eff"
            >
              <template v-for="route in routes">
                <el-menu-item
                  :key="route.path"
                  :index="route.path"
                  v-if="!route.children"
                >
                  {{ route.meta.title }}
                </el-menu-item>
                <el-submenu
                  :key="route.path"
                  :index="route.path"
                  v-if="route.children && route.children.length"
                >
                  <template slot="title">{{ route.meta.title }}</template>
                  <template v-for="item in route.children">
                    <el-menu-item
                      :key="item.path"
                      :index="`${route.path}/${item.path}`"
                    >
                      {{ item.meta.title }}
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-bar h5">
      <div class="nav-bar-fixed">
        <div class="nav-bar-container">
          <div class="nav-bar-logo" @click="goHome">
            <img :src="logo" alt="" />
          </div>
          <div
            :class="{ 'h5-btn-box': true, 'active-h5-btn-box': isActive }"
            @click="openModal"
          >
            <div class="h5-btn">
              <div class="h5-btn-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="h5-menu">
        <el-drawer
          title="我是标题"
          :visible.sync="isActive"
          :with-header="false"
          custom-class="drawer"
          :modal="true"
        >
          <div
            class="menu-list"
            v-for="item in navList"
            :key="item.name"
            @click="goPage(item.path)"
          >
            <div class="menu-list-title">{{ item.name }}</div>
            <div class="menu-list-arrow">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-drawer>
      </div>
      <div class="go-to-top" @click="goToTop">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      activeIndex: "1",
      routes: [],
      logo: require("../../../assets/image/logo.png"),
      isActive: false,
      navList: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "小微业务",
          path: "/smallBusinesses",
        },
        {
          name: "产品服务",
          path: "/technologyProducts",
        },
        {
          name: "关于我们",
          path: "/about",
        },
        {
          name: "加入我们",
          path: "/joinUs",
        },
      ],
    };
  },
  created() {
    this.routes = this.$router.options.routes[0].children;
  },
  methods: {
    goToTop() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    goPage(path) {
      this.isActive = false;
      this.$router.push(path);
    },
    openModal() {
      this.isActive = !this.isActive;
    },
    goHome() {
      this.$router.push("/");
    },
    handleSelect() {},
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;
  height: 83px;
  background: #ffffff;
  z-index: 1000;
  .nav-bar-fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 83px;
    background: #ffffff;
    display: flex;
    align-items: center;
  }
  .nav-bar-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .nav-bar-logo {
      width: 163px;
      height: 44px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .nav-bar-right {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
  .go-to-top {
    display: none;
  }
  ::v-deep {
    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      font-size: 18px;
      font-weight: bold;
      color: #333333 !important;
      border-bottom-color: #4782f7 !important;
    }
    .el-menu--horizontal > .el-menu-item {
      font-size: 18px;
      font-weight: 300;
      color: #52525a;
      height: 83px;
      line-height: 83px;
      margin: 0px 28px;
    }
  }
}
.hidden-nav-bar {
  transform: translate3d(0, -82px, 0);
  z-index: 0;
  transition: all 0.3s linear;
}
.h5 {
  display: none;
}
@media screen and (min-width: 992px) {
  .h5-btn-box {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .nav-bar {
    height: 292px;
    .nav-bar-fixed {
      height: 292px;
      padding: 0px 123px;
      box-sizing: border-box;
      z-index: 5000;
      .nav-bar-container {
        width: 100%;
        justify-content: space-between;
        .nav-bar-logo {
          width: 502px;
          height: 138px;
        }
        .nav-bar-right {
          display: none;
        }
        .h5-btn-box {
          width: 151px;
          height: 133px;
          padding: 26px;
          box-sizing: border-box;
          .h5-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            &:before {
              content: "";
              display: block;
              width: 100%;
              height: 10px;
              background: #333333;
              position: absolute;
              top: 0px;
              transition: all 0.2s;
            }
            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 10px;
              background: #333333;
              position: absolute;
              bottom: 0px;
              transition: all 0.2s;
            }
            .h5-btn-line {
              width: 100%;
              height: 10px;
              background: #333333;
              transition: background 0.2s;
            }
          }
        }
        .active-h5-btn-box {
          .h5-btn {
            &:before {
              transform: translateY(1.8667vw) rotate(45deg);
              transition: all 0.2s;
            }
            &:after {
              transform: translateY(-1.8667vw) rotate(-45deg);
              transition: all 0.2s;
            }
            .h5-btn-line {
              background: transparent;
              transition: background 0.2s;
            }
          }
        }
      }
    }
    .drawer {
      width: 66.66vw !important;
      .menu-list {
        border-bottom: 1px solid #e7e7e7;
        height: 207px;
        padding: 64px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menu-list-title {
          font-size: 56px;
          font-weight: 400;
          color: #333333;
          line-height: 66px;
        }
        .menu-list-arrow {
          color: #666666;
        }
      }
    }
    .go-to-top {
      display: block;
      width: 202px;
      height: 202px;
      border: 1px solid #4782f7;
      line-height: 202px;
      text-align: center;
      font-size: 118px;
      position: fixed;
      right: 77px;
      bottom: 10%;
      background: #ffffff;
    }
  }
  .h5 {
    display: block;
  }
  .pc {
    display: none;
  }
  #top-nav-bar {
    display: none;
  }
  ::v-deep {
    .drawer {
      width: 66.66vw !important;
      padding: 346px 95px 0px;
    }
    .el-drawer__wrapper {
      background: rgba(20, 20, 20, 0.6);
    }
  }
}
</style>
