<template>
  <div class="main-container">
    <router-view :key="key"></router-view>
  </div>
</template>

<script>
export default {
  name: "PageMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped></style>
