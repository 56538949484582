import Vue from "vue";
import {
  Button,
  Tabs,
  TabPane,
  Menu,
  Submenu,
  MenuItem,
  Carousel,
  CarouselItem,
  Divider,
  Row,
  Col,
  Drawer,
} from "element-ui";

Vue.use(Button);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Divider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Drawer);
