<template>
  <div class="footer">
    <div class="base-container">
      <div class="footer-bar">
        <div class="footer-logo">
          <img :src="footerLogo" alt="" />
        </div>
        <div class="footer-bar-menu">
          <div class="menu-list" @click="goToLink('/')">首页</div>
          <div class="menu-list" @click="goToLink('/smallBusinesses')">
            小微业务
          </div>
          <div class="menu-list" @click="goToLink('/technologyProducts')">
            产品服务
          </div>
          <div class="menu-list" @click="goToLink('/about')">关于我们</div>
          <!--          <div class="menu-list" @click="goToLink('home')">新闻动态</div>-->
          <div class="menu-list" @click="goToLink('/joinUs')">加入我们</div>
        </div>
      </div>
      <div class="footer-container">
        <div class="container-left">
          <div class="weChat-official-account">微信公众号：magfin001</div>
          <div class="weChat-qr-code">
            <img :src="qrCode" alt="" />
          </div>
        </div>
        <div class="container-right">
          <div class="com-info">
            <div class="com-info-list">
              <div class="info-list-left-icon">
                <img :src="iconContactUs" alt="" />
              </div>
              <div class="info-list-right">
                <div class="info-title">联系我们</div>
                <div class="info-container">contact@magfin.cn</div>
              </div>
            </div>
            <div class="com-info-list">
              <div class="info-list-left-icon">
                <img :src="iconPhone" alt="" />
              </div>
              <div class="info-list-right">
                <div class="info-title">客服热线</div>
                <div class="info-container">021-50871163</div>
              </div>
            </div>
            <div class="com-info-list">
              <div class="info-list-left-icon">
                <img :src="iconAddress" alt="" />
              </div>
              <div class="info-list-right">
                <div class="info-title">公司地址</div>
                <div class="info-container">
                  上海市徐汇区龙耀路175号星扬西岸中心27楼09室
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-filings">
        <div class="filings-left">
          沪公网安备 31010402007581号 沪ICP备 16000005号
        </div>
        <div class="filings-right">
          © 2015-{{ new Date().getFullYear() }} 上海孚厘科技有限公司
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      footerLogo: require("../../../assets/image/footer-logo.png"),
      qrCode: require("../../../assets/image/qr_code.png"),
      iconContactUs: require("../../../assets/image/icon_contact_us.png"),
      iconPhone: require("../../../assets/image/icon_phone.png"),
      iconAddress: require("../../../assets/image/icon_address.png"),
    };
  },
  methods: {
    goToLink(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 10px 0px 30px;
  background: #224e98;

  .footer-bar {
    height: 86px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4166a5;

    .footer-logo {
      width: 300px;
      height: 38px;

      img {
        width: 139px;
        height: auto;
      }
    }

    .footer-bar-menu {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .menu-list {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .footer-container {
    display: flex;
    padding: 30px 0px 50px;
    border-bottom: 1px solid #4166a5;

    .container-left {
      width: 300px;

      .weChat-official-account {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        margin-bottom: 30px;
      }

      .weChat-qr-code {
        width: 145px;
        height: 145px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .container-right {
      flex: 1;

      .com-info {
        .com-info-list {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          &:last-child {
            margin-bottom: 0px;
          }

          .info-list-left-icon {
            width: 32px;
            height: 32px;
            margin-right: 20px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .info-list-right {
            .info-title {
              height: 24px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.85);
              line-height: 24px;
            }
            .info-container {
              height: 24px;
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .footer-filings {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .filings-left,
    .filings-right {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
    }
  }
}
@media screen and (max-width: 992px) {
  .footer {
    padding: 80px 0px;
    .footer-bar {
      display: none;
    }
    .footer-container {
      display: flex;
      justify-content: space-between;

      .container-left {
        width: auto;
        display: flex;
        flex-direction: column-reverse;
        .weChat-official-account {
          height: auto;
          font-size: 56px;
          font-weight: 400;
          color: #ffffff;
          line-height: 66px;
          margin-top: 89px;
        }
        .weChat-qr-code {
          height: 373px;
          width: 373px;
        }
      }
      .container-right {
        flex: none;
        .com-info {
          .com-info-list {
            .info-list-left-icon {
              width: 61px;
              height: 61px;
            }
            .info-list-right {
              .info-title {
                height: auto;
                font-size: 46px;
                font-weight: 400;
                color: #ffffff;
                line-height: 72px;
              }
              .info-container {
                height: auto;
                font-size: 51px;
                font-weight: bold;
                color: #ffffff;
                line-height: 67px;
              }
            }
          }
        }
      }
    }
    .footer-filings {
      flex-direction: column;
      padding: 82px 0px 0px;
      margin-top: 0px;
      .filings-left,
      .filings-right {
        font-size: 46px;
        font-weight: 400;
        color: #ffffff;
        line-height: 72px;
      }
    }
  }
}
</style>
