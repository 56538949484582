import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./util/lib-flexible/flexible"; // 动态更改根节点字体大小
import "./config/elementUse"; // 按需引入element
import "./assets/style/index.scss"; // 全局公共样式
import animated from "animate.css";
import { WOW } from "wowjs";

Vue.use(animated);

Vue.config.productionTip = false;

Vue.prototype.$wow = new WOW({
  boxClass: "wow", // default
  animateClass: "animate__animated", // default
  offset: 0, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
