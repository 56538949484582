<template>
  <div class="home">
    <div class="swiper pc banner-swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide1">
            <div class="carousel-item">
              <img
                class="pc-img"
                :src="require('../assets/image/swiper_img1.webp')"
                alt=""
                ref="bannerHeight"
              />
              <div class="carousel-item-container">
                <div class="base-container">
                  <div class="carousel-item-title">帮小微企业活得更好</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide swiper-slide2">
            <div class="carousel-item">
              <img
                class="pc-img"
                :src="require('../assets/image/swiper_img2.webp')"
                alt=""
              />
              <div class="carousel-item-container">
                <div class="base-container">
                  <div class="carousel-item-inline">
                    <div class="carousel-item-title text-left">
                      小微金融专家
                    </div>
                    <div class="carousel-item-title1">永远走正道</div>
                    <div class="carousel-item-desc">
                      超级信贷员+信贷工厂+数据模型
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="swiper h5">
      <el-carousel ref="slideCarousel" trigger="click" :autoplay="true">
        <el-carousel-item>
          <div class="carousel-item">
            <img
              class="h5-img"
              :src="require('../assets/image/iphone/swiper_bg1.png')"
              alt=""
            />
            <div class="carousel-item-container">
              <div class="base-container">
                <div class="carousel-item-title">帮小微企业活得更好</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel-item">
            <img
              class="h5-img"
              :src="require('../assets/image/iphone/swiper_bg2.png')"
              alt=""
            />
            <div class="carousel-item-container">
              <div class="base-container">
                <div class="carousel-item-inline">
                  <div class="carousel-item-title text-left">小微金融专家</div>
                  <div class="carousel-item-title1">永远走正道</div>
                  <div class="carousel-item-desc">
                    超级信贷员+信贷工厂+数据模型
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="base-container home-container">
      <div class="business-wrapper">
        <div
          class="base-title business-title wow animate__animated animate__fadeInUp"
        >
          一家懂业务的金融科技公司
        </div>
        <div class="business-describe wow animate__animated animate__fadeInUp">
          <p>
            孚厘科技坚持秉持"帮小微企业活得更好"的使命，践行“升级版台州模式”坚持以金融+科技相结合致力于成为小微金融行业领军者
          </p>
        </div>
        <div class="business-box wow animate__animated animate__fadeInUp">
          <div class="business-list">
            <div class="business-list-icon left-icon"></div>
            <div class="business-list-right">
              <div class="business-list-right-title">使命</div>
              <div class="business-list-right-text">帮小微企业活得更好</div>
            </div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="business-list">
            <div class="business-list-icon right-icon"></div>
            <div class="business-list-right">
              <div class="business-list-right-title">价值观</div>
              <div class="business-list-right-text">永远走正道</div>
            </div>
          </div>
          <div class="business-more-wrapper">
            <div class="business-more" @click="linkToSmallBusinesses"></div>
          </div>
        </div>
      </div>
      <!--产品-->
      <div class="area">
        <div
          class="base-title area-title wow animate__animated animate__fadeInUp"
        >
          孚厘科技：深耕小微业务多年
        </div>
        <div class="area-list-wrapper">
          <div class="area-list wow animate__animated animate__fadeInLeft">
            <div class="area-list-top top1">专注普惠</div>
            <div class="area-list-bottom">小微经营性资产供应</div>
          </div>
          <div class="area-list wow animate__animated animate__fadeInRight">
            <div class="area-list-top top2">风控显著</div>
            <div class="area-list-bottom">多年打磨小微风控</div>
          </div>
          <div class="area-list wow animate__animated animate__fadeInLeft">
            <div class="area-list-top top3">精英团队</div>
            <div class="area-list-bottom">深耕小微细分领域</div>
          </div>
          <div class="area-list wow animate__animated animate__fadeInRight">
            <div class="area-list-top top4">创新模式</div>
            <div class="area-list-bottom">互联网＋小微生态圈</div>
          </div>
        </div>
      </div>
      <!--pc案例-->
      <div class="pc-case-wrapper case-wrapper">
        <div class="case-list wow animate__animated animate__fadeInLeft">
          <div class="case-img case-img-bg1"></div>
          <div class="case-desc">
            <div class="case-desc-title">助力金融机构小微业务发展</div>
            <div class="case-desc-details">
              与某农商行合作，针对行内当前业务现状、分析并给出解决方案，帮助银行业务改革，大大缩短了审批时效,也减少了上下沟通和让客户反复补充资料的成本,最重要的是通过优化准入和贷后风控的退出环节,该行新发放的普惠贷款不良率始终保持在1.5%以内。
            </div>
            <div class="case-more" @click="goToProduct">
              了解更多
              <img :src="caseMore" alt="" />
            </div>
          </div>
        </div>
        <div class="case-list wow animate__animated animate__fadeInRight">
          <div class="case-desc">
            <div class="case-desc-title">
              <div style="margin-bottom: 5px">为金融机构提供</div>
              <div>最适合小微业务的风控工具</div>
            </div>
            <div class="case-desc-details">
              孚厘结合自身业务实践倾力打造，将传统非标小微客户风控流程标准化，通过对于各类小微数据的解析和专家经验数字化运用，不仅帮助金融机构进行客户筛选，快速定位风险点，对客户群体进行分层分类，实现“拟人”风控决策系统；也降低人员依赖，提升团队经办效率，提高风控能力，突破小微金融非标业务标准化的难题，显著缩短超级信贷员培养周期。
            </div>
            <div class="case-more" @click="goToProduct">
              了解更多
              <img :src="caseMore" alt="" />
            </div>
          </div>
          <div class="case-img case-img-bg2"></div>
        </div>
        <div class="case-list wow animate__animated animate__fadeInLeft">
          <div class="case-img case-img-bg3"></div>
          <div class="case-desc">
            <div class="case-desc-title">
              <div style="margin-bottom: 5px">实战辅导</div>
              <div>有效提升金融机构营销能力</div>
            </div>
            <div class="case-desc-details">
              某农商银行合作了营销实战辅导项目,首先优化了全行基层营销管理执行的细则和要求，通过“龙虎榜”、“金银牌机制”、“一对一PK”等多项制度营造了“赶学比超”的营销氛围。同时,辅导首月就接连突破营销历史记录,贷款同比发放增长600%多,被省联社在大会上点名表扬。
            </div>
            <div class="case-more" @click="goToProduct">
              了解更多
              <img :src="caseMore" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--   h5案例   -->
      <div class="h5-case-wrapper case-wrapper">
        <div class="h5-swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide1">
              <div class="case-list">
                <div class="case-img case-img-bg1"></div>
                <div class="case-desc">
                  <div class="case-desc-title">助力金融机构小微业务发展</div>
                  <div class="case-desc-details">
                    与某农商行合作，针对行内当前业务现状、分析并给出解决方案，帮助银行业务改革，大大缩短了审批时效,也减少了上下沟通和让客户反复补充资料的成本,最重要的是通过优化准入和贷后风控的退出环节,该行新发放的普惠贷款不良率始终保持在1.5%以内。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-slide2">
              <div class="case-list">
                <div class="case-img case-img-bg2"></div>
                <div class="case-desc">
                  <div class="case-desc-title">
                    <div style="margin-bottom: 5px">为金融机构提供</div>
                    <div>最适合小微业务的风控工具</div>
                  </div>
                  <div class="case-desc-details">
                    孚厘结合自身业务实践倾力打造，将传统非标小微客户风控流程标准化，通过对于各类小微数据的解析和专家经验数字化运用，不仅帮助金融机构进行客户筛选，快速定位风险点，对客户群体进行分层分类，实现“拟人”风控决策系统；也降低人员依赖，提升团队经办效率，提高风控能力，突破小微金融非标业务标准化的难题，显著缩短超级信贷员培养周期。
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide swiper-slide3">
              <div class="case-list">
                <div class="case-img case-img-bg3"></div>
                <div class="case-desc">
                  <div class="case-desc-title">
                    <div style="margin-bottom: 5px">实战辅导</div>
                    <div>有效提升金融机构营销能力</div>
                  </div>
                  <div class="case-desc-details">
                    某农商银行合作了营销实战辅导项目,首先优化了全行基层营销管理执行的细则和要求，通过“龙虎榜”、“金银牌机制”、“一对一PK”等多项制度营造了“赶学比超”的营销氛围。同时,辅导首月就接连突破营销历史记录,贷款同比发放增长600%多,被省联社在大会上点名表扬。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--合作机构-->
      <div class="partners-wrapper">
        <div
          class="base-title partners-title wow animate__animated animate__fadeInUp"
        >
          合作机构
        </div>
        <div
          class="partners-list-wrapper wow animate__animated animate__zoomInUp"
        >
          <div class="partners-list">
            <div
              class="partners-list-item"
              v-for="(item, index) in partnersList"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "Home",
  data() {
    return {
      carouselImg: require("../assets/image/sweiper_img.png"),
      caseMore: require("../assets/image/case_more.png"),
      partnersList: [],
      swiperHeight: 0,
    };
  },
  mounted() {
    document.getElementById("top-nav-bar").className = "nav-bar";
    this.initBannerSwiper();
    this.initProductSwiper();
    const partnersNum = 15;
    const partnersList = [];
    const windowWidth = document.body.clientWidth;
    if (windowWidth > 992) {
      for (let i = 1; i <= partnersNum; i++) {
        partnersList.push(require(`../assets/image/bank_icon${i}.png`));
      }
    } else {
      for (let i = 1; i <= partnersNum; i++) {
        partnersList.push(require(`../assets/image/iphone/bank_icon${i}.png`));
      }
    }
    this.partnersList = partnersList;
    window.onresize = () => {
      let partnersList = [];
      let currentWidth = document.body.clientWidth;
      if (currentWidth > 992) {
        for (let i = 1; i <= partnersNum; i++) {
          partnersList.push(require(`../assets/image/bank_icon${i}.png`));
        }
      } else {
        for (let i = 1; i <= partnersNum; i++) {
          partnersList.push(
            require(`../assets/image/iphone/bank_icon${i}.png`)
          );
        }
      }
      this.partnersList = partnersList;
    };
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    initBannerSwiper() {
      new Swiper(".swiper-container", {
        autoplay: true,
        loop: true,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },
    initProductSwiper() {
      new Swiper(".h5-swiper-container", {
        autoplay: true,
        loop: true,
        slidesPerView: 1.2, //一个slides容器内展示的slides数量 可以为小数点
        spaceBetween: 0, //在slides之间设置间距
      });
    },
    goToProduct() {
      this.$router.push("/technologyProducts");
    },
    linkToSmallBusinesses() {
      this.$router.push("/smallBusinesses");
    },
  },
  beforeDestroy() {
    window.onresize = () => {};
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: #ffffff;

  .swiper-container {
    height: calc(100vh - 83px);
  }

  .home-container {
    padding: 80px 0px;

    .base-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
    }

    .business-wrapper {
      .business-describe {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        text-align: center;
        margin: 24px 0px 40px;

        p {
          margin: 0;
        }
      }

      .business-box {
        width: 100%;
        display: flex;
        padding: 40px 52px;
        border-radius: 10px;
        background: #f7f8fa;
        box-sizing: border-box;

        .business-list {
          flex: 1;
          display: flex;

          .business-list-icon {
            height: 100px;
            width: 100px;
            background-size: 100%;
            margin-right: 25px;
          }

          .left-icon {
            background-image: url("../assets/image/business_left_icon.png");
          }

          .right-icon {
            margin-left: 52px;
            background-image: url("../assets/image/business_right_icon.png");
          }

          .business-list-right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .business-list-right-title {
              font-size: 20px;
              font-weight: bold;
              color: #999999;
              line-height: 26px;
              margin-bottom: 10px;
            }

            .business-list-right-text {
              font-size: 28px;
              font-weight: bold;
              color: #333333;
              line-height: 33px;
            }
          }
        }

        .business-more-wrapper {
          display: flex;
          align-items: center;

          .business-more {
            height: 49px;
            width: 49px;
            cursor: pointer;
            background-image: url("../assets/image/business_more.png");
            background-size: 100%;
          }
        }
      }
    }

    .area {
      .area-title {
        margin: 80px 0px 50px;
      }

      .area-list-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .area-list {
          width: 584px;
          border-radius: 3px;
          overflow: hidden;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          &:nth-child(-n + 2) {
            margin-bottom: 32px;
          }

          .area-list-top {
            display: flex;
            align-items: center;
            height: 143px;
            background-size: 100%;
            font-size: 32px;
            font-weight: bold;
            color: #ffffff;
            line-height: 48px;
            padding-left: 76px;
          }

          .top1 {
            background-image: url("../assets/image/area-list-bg1.png");
          }

          .top2 {
            background-image: url("../assets/image/area-list-bg2.png");
          }

          .top3 {
            background-image: url("../assets/image/area-list-bg3.png");
          }

          .top4 {
            background-image: url("../assets/image/area-list-bg4.png");
          }

          .area-list-bottom {
            height: 110px;
            display: flex;
            align-items: center;
            padding-left: 76px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 26px;
          }
        }
      }
    }

    .case-wrapper {
      margin-top: 80px;

      .case-list {
        display: flex;
        margin-bottom: 100px;

        &:last-child {
          margin-bottom: 0px;
        }

        .case-img {
          width: 600px;
          height: 418px;
          background-size: 100% 100%;
        }

        .case-img-bg1 {
          background-image: url("../assets/image/case_list_bg1.png");
        }

        .case-img-bg2 {
          background-image: url("../assets/image/case_list_bg3.png");
        }

        .case-img-bg3 {
          background-image: url("../assets/image/case_list_bg2.png");
        }
      }

      .case-desc {
        width: 600px;
        padding: 60px 42px 0px;
        background: #f7f8fa;
        box-sizing: border-box;

        .case-desc-title {
          width: 340px;
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          line-height: 33px;
          position: relative;
          padding-bottom: 20px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0px;
            bottom: 5px;
            height: 2px;
            width: 100%;
            background: linear-gradient(
              to right,
              #db2534 0%,
              #db2534 30%,
              #4782f7 30%,
              #4782f7 100%
            );
          }
        }

        .case-desc-details {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          margin: 40px 0px;
        }

        .case-more {
          width: 134px;
          height: 42px;
          background: #4782f7;
          border-radius: 2px;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 21px;
            height: 8px;
            margin-left: 10px;
          }
        }
      }
    }

    .h5-case-wrapper {
      display: none;
    }

    .partners-wrapper {
      .partners-title {
        margin: 80px 0px 40px;
      }
      .partners-list-wrapper {
        .partners-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .partners-list-item {
            padding: 17px;
            background: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(182, 182, 182, 0.25);
            margin-bottom: 28px;
            &:hover {
              img {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
              }
            }
            img {
              width: 183px;
              height: 55px;
            }
          }
        }
      }
    }
  }

  .carousel-item {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      min-height: 100%;
    }
    .h5-img {
      display: none;
    }

    .carousel-item-container {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .notice-wrapper {
        width: 100%;
        height: 100%;
        .notice-title {
          text-align: center;
          height: 52px;
          font-size: 50px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          line-height: 52px;
          //text-shadow: 0px 5px 0px rgba(0, 0, 0, 0.25);
          background: linear-gradient(180deg, #f4c2a4 0%, #fbf5dc 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 45px;
        }
        .notice-content {
          background-image: url("../assets/image/notice_content_bg.webp");
          background-size: 100% auto;
          background-repeat: no-repeat;
          margin: 20px auto 0px;
          width: 82.6%;
          height: 714px;
        }
      }
      .base-container {
        display: flex;
        justify-content: center;
      }
      .carousel-item-inline {
        display: inline-block;
      }

      .carousel-item-title {
        font-size: 53px;
        font-weight: bold;
        color: #ffffff;
        line-height: 53px;
        text-align: center;
      }
      .carousel-item-title1 {
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        line-height: 52px;
        margin-top: 23px;
      }
      .carousel-item-desc {
        font-size: 40px;
        font-weight: 400;
        color: #ffffff;
        line-height: 52px;
        margin-top: 23px;
      }
      .text-left {
        text-align: left;
      }
    }
  }

  ::v-deep {
    .banner-swiper {
      .swiper-pagination-bullet {
        background: #ffffff;
        opacity: 1;
        height: 10px;
        width: 10px;
        margin: 0 10px;
      }
      .swiper-pagination-bullet-active {
        width: 65px;
        height: 10px;
        background: #4782f7;
        border-radius: 43px;
      }
      .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 20px;
      }
    }

    .el-divider--vertical {
      height: 100px;
      margin: 0px 53px;
    }

    .el-carousel__arrow {
      display: none;
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .home {
    .home-container {
      padding: 154px 77px;
      .base-title {
        font-size: 82px;
        color: #333333;
        line-height: 123px;
      }
      .business-wrapper {
        .business-describe {
          font-size: 56px;
          font-weight: 400;
          color: #666666;
          line-height: 66px;
          margin-top: 64px;
          margin-bottom: 105px;
        }
        .business-box {
          flex-direction: column;
          padding: 84px 143px;
          box-sizing: border-box;
          .business-list {
            padding: 84px 0px 84px 143px;
            .business-list-icon {
              height: 256px;
              width: 256px;
            }
            .right-icon {
              margin-left: 0px;
            }
            .business-list-right {
              .business-list-right-title {
                font-size: 51px;
                line-height: 67px;
                margin-bottom: 23px;
              }
              .business-list-right-text {
                font-size: 72px;
                line-height: 84px;
              }
            }
          }

          .business-more-wrapper {
            display: none;
          }
        }
      }
      .area {
        .area-title {
          margin: 154px 0px 105px;
        }
        .area-list-wrapper {
          .area-list {
            width: 842px;
            &:nth-child(-n + 2) {
              margin-bottom: 82px;
            }
            .area-list-top {
              height: 366px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              font-size: 82px;
              line-height: 123px;
              padding-left: 195px;
            }
            .area-list-bottom {
              height: 282px;
              text-align: center;
              padding-left: 0px;
              font-size: 51px;
              line-height: 67px;
              justify-content: center;
            }
          }
        }
      }
      .h5-case-wrapper {
        display: block;
        overflow: hidden;
        .case-list {
          flex-direction: column;
          width: 1409px;
          .case-img {
            width: 1409px;
            height: 1070px;
            background-size: 100% 100%;
          }
          .case-desc {
            width: 100%;
            height: 1107px;
            box-sizing: border-box;
            padding: 148px 90px 80px;
            .case-desc-title {
              width: 983px;
              line-height: 84px;
              font-size: 72px;
              padding-bottom: 60px;
              &:before {
                height: 3px;
              }
            }
            .case-desc-details {
              font-size: 56px;
              color: #666666;
              line-height: 76px;
              margin-top: 58px;
            }
          }
        }
      }
      .pc-case-wrapper {
        display: none;
      }
      .partners-wrapper {
        .partners-title {
          margin: 154px 0px 105px;
        }
        .partners-list-wrapper {
          .partners-list {
            .partners-list-item {
              padding: 44px;
              img {
                width: 301px;
                height: 141px;
              }
              &:nth-last-child(-n + 3) {
                img {
                  width: 454px;
                }
              }
            }
          }
        }
      }
    }
    .carousel-item {
      .h5-img {
        display: block;
      }
      .pc-img {
        display: none;
      }
      .carousel-item-container {
        .carousel-item-title {
          font-size: 136px;
          line-height: 133px;
        }
        .carousel-item-title1 {
          font-size: 102px;
          line-height: 133px;
          margin-top: 41px;
        }
        .carousel-item-desc {
          line-height: 133px;
          font-size: 102px;
          margin-top: 41px;
        }
      }
    }
    .pc {
      display: none;
    }
    .h5 {
      display: block;
    }
    ::v-deep {
      .swiper {
        .el-carousel__container {
          height: 1851px;
        }
      }
      .el-divider--vertical {
        display: none;
      }
    }
  }
}
</style>
