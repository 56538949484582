import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../components/layout/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/smallBusinesses",
        name: "SmallBusinesses",
        meta: {
          title: "小微业务",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/SmallBusinesses.vue"
          ),
      },
      {
        path: "/technologyProducts",
        name: "TechnologyProducts",
        component: () =>
          import(
            /* webpackChunkName: "blades" */ "../views/TechnologyProducts.vue"
          ),
        meta: {
          title: "产品服务",
        },
      },
      {
        path: "/about",
        name: "About",
        meta: {
          title: "关于我们",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "/joinUs",
        name: "JoinUs",
        meta: {
          title: "加入我们",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/JoinUs.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
