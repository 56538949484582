<template>
  <div class="html-container">
    <NavBar></NavBar>
    <PageMain></PageMain>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import PageMain from "./components/PageMain";
import PageFooter from "./components/PageFooter";

export default {
  name: "layout",
  components: {
    NavBar,
    PageMain,
    PageFooter,
  },
};
</script>

<style scoped></style>
